<template>
  <div class="apply-popup-container" v-if="visible">
    <van-popup
      round
      :value="visible"
      position="center"
      class="apply-popup"
      get-container=".apply-popup-container"
      :style="{ height: 'max-content', width: '85%' }"
      :close-icon="require('@/assets/home/close.svg')"
      @click-close-icon="onClose"
      @click-overlay="onClose"
      :close-on-popstate="true"
      closeable
    >
      <div class="title-container">{{ title }}</div>
      <div v-if="message" class="message">
        <div>{{ message }}</div>
        <img v-if="imgSrc" :src="imgSrc" alt="" />
      </div>
      <slot></slot>
      <div class="apply-content" v-if="showInput">
        <van-field
          label-width="50"
          v-model="inputContent"
          class="img-row"
          label=""
          type="textarea"
          maxlength="200"
          placeholder="请审核理由"
          show-word-limit
        />
      </div>
      <div class="ns-dialog-btn-container" v-if="showConfirm || showCancel">
        <van-button
          v-if="showCancel"
          block
          plain
          size="small"
          class="ns-btn"
          @click="onCancel"
          round
        >
          {{ cancelText }}
        </van-button>
        <van-button
          v-if="showConfirm"
          class="ns-btn ns-main-btn"
          type="danger"
          size="small"
          round
          block
          @click="onConfirm"
        >
          {{ confirmText }}
        </van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
/**
 * custom-dialog组件
 */
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    showInput: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    imgSrc: {
      type: String,
      default: '',
    },
    showCancel: {
      type: Boolean,
      default: true,
    },
    cancelText: {
      type: String,
      default: '取消',
    },
    showConfirm: {
      type: Boolean,
      default: true,
    },
    confirmText: {
      type: String,
      default: '确定',
    },
    reject: {
      type: Function,
      default: null,
    },
    resolve: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      inputContent: null,
    }
  },
  methods: {
    onClose() {
      if (this.reject) {
        this.reject()
        this.visible = false
      }
      this.$emit('update:visible', false)
    },
    onCancel() {
      this.onClose()
      this.$emit('cancel')
    },
    onConfirm() {
      if (this.resolve) {
        this.resolve()
      }
      this.onClose()
      this.$emit('confirm', this.inputContent)
    },
  },
}
</script>

<style scoped lang="less">
.apply-popup-container {
  height: 100vh;

  ::v-deep .van-popup__close-icon {
    font-size: 12px;
    top: 20px;
    right: 16px;
    color: #333333;
  }
  ::v-deep .van-icon-cross:before {
    font-size: 12px;
  }
  .apply-popup {
    .title-container {
      text-align: center;
      padding-top: 20px;
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      line-height: 22px;
    }
    .message {
      padding: 30px 12px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
      text-align: center;
      img {
        width: 100%;
      }
    }

    .apply-content {
      margin: 30px 16px 20px;
      padding: 12px 16px;
      height: 90px;
      background: #f6f6f6;
      border-radius: 8px;

      .img-row {
        background: #f6f6f6;
        max-height: 100%;
        padding: 0;
      }
      ::v-deep .van-field__control::-webkit-input-placeholder {
        font-size: 14px;
        color: #999999;
        font-weight: 400;
      }

      .commit-tip {
        padding: 0 20px;

        .tip {
          color: @text-color-title;
          margin-bottom: 12px;
        }

        .link-span {
          color: @primary-color;
          font-weight: 500;
        }
      }
    }

    .bottom-container {
      display: flex;
      justify-content: center;
      padding: 10px 20px 20px;
      position: fixed;
      bottom: 0;
      left: 0;
      width: calc(100% - 40px);
    }
  }
}
</style>
