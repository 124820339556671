<template>
  <van-tabbar class="common-tab" :value="active" active-color="#FF264A">
    <van-tabbar-item
      v-for="(item, index) in tabList"
      :key="index"
      v-show="item.show"
      replace
      :name="item.name"
      :to="item.link"
      :badge="item.badge"
    >
      {{ item.desc }}
      <template #icon="props">
        <img
          class="img-icon"
          :src="props.active ? item.inactive : item.active"
          alt=""
        />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    active: { type: String, default: 'home' },
  },
  computed: {
    ...mapGetters([
      'saleMode',
      'getDistinctGoodsCount',
      'shoppingCart',
      'isInside',
    ]),
    tabList() {
      if (this.saleMode)
        return [
          {
            link: '/',
            icon: 'home-o',
            name: 'home',
            desc: '首页',
            active: require('@/assets/home/home.png'),
            inactive: require('@/assets/home/home-active.png'),
            show: !this.isInside,
          },
          {
            link: '/new-goods',
            icon: 'cart-o',
            name: 'goods',
            desc: ' 提货到家',
            active: require('@/assets/home/goods.png'),
            inactive: require('@/assets/home/goods-active.png'),
            show: !this.isInside,
          },
          {
            link: '/qyCustomerList',
            icon: 'cart-o',
            name: 'behalf',
            desc: ' 一件代发',
            active: require('@/assets/home/behalf.png'),
            inactive: require('@/assets/home/behalf-active.png'),
            show: true,
          },
          {
            link: '/new-shoppingCart',
            icon: 'setting-o',
            name: 'shoppingCart',
            desc: '购物车',
            badge: this.getDistinctGoodsCount || undefined,
            active: require('@/assets/home/shopping-cart.png'),
            inactive: require('@/assets/home/shopping-cart-active.png'),
            show: !this.isInside,
          },
          {
            link: '/new-mine',
            icon: 'friends-o',
            name: 'newMine',
            desc: '我的',
            active: require('@/assets/home/mine.png'),
            inactive: require('@/assets/home/mine-active.png'),
            show: true,
          },
        ]
      return [
        {
          link: '/',
          icon: 'home-o',
          name: 'home',
          desc: '首页',
          active: require('../assets/home/home.png'),
          inactive: require('../assets/home/home-active.png'),
          show: true,
        },
        {
          link: '/goods',
          icon: 'cart-o',
          name: 'goods',
          desc: '分类',
          active: require('../assets/home/goods.png'),
          inactive: require('../assets/home/goods-active.png'),
          show: true,
        },
        {
          link: '/shoppingCart',
          icon: 'setting-o',
          name: 'shoppingCart',
          desc: '购物车',
          badge: this.getDistinctGoodsCount || undefined,
          active: require('@/assets/home/shopping-cart.png'),
          inactive: require('@/assets/home/shopping-cart-active.png'),
          show: true,
        },
        // {
        //   link: '/work',
        //   icon: 'setting-o',
        //   name: 'work',
        //   desc: '工作台',
        //   active: require('../assets/home/work.png'),
        //   inactive: require('../assets/home/work-active.png'),
        // },
        {
          link: '/mine',
          icon: 'friends-o',
          name: 'mine',
          desc: '我的',
          active: require('../assets/home/mine.png'),
          inactive: require('../assets/home/mine-active.png'),
          show: true,
        },
      ]
    },
  },
}
</script>

<style scoped lang="less">
.common-tab {
  ::v-deep .van-info {
    background: @primary-color;
  }
  .img-icon {
    width: 22px;
    height: 22px;
  }
}
</style>
