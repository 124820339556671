const getters = {
  access_token: (state) => state.user.access_token,
  refresh_token: (state) => state.user.refresh_token,
  saleMode: (state) => state.user.saleMode,
  expires_in: (state) => state.user.expires_in,
  roles: (state) => state.user.roles,
  permissions: (state) => state.user.permissions,
  userInfo: (state) => state.user.userInfo,
  customerInfo: (state) => state.user.customerInfo,
  currentSelectRole: (state) => state.user.currentSelectRole,
  currentSelectMode: (state) => state.user.currentSelectMode,
  oldList: (state) => state.shoppingPackage.oldList,
  gift: (state) => state.shoppingPackage.gift,
  isInside: (state) => state.user.currentSelectRole.companyPartnerSubType === 3,
}

export default getters
